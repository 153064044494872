import React, { Component } from 'react';
import uuid from 'uuid';

class FileUploadAlternative extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unique: uuid.v1(),
            promptId: props.promptId,
            alt: props.alt
        };

        this.handleFileChange = props.onFileChange;
    }

    render () {
        let { unique, promptId } = this.state;
        let { alt } = this.state;
        return <div><input type="file"
                           id={unique}
                           disabled={this.props.disabled}
                           onChange={(e) =>
                              this.handleFileChange(e.target.files, promptId, alt.key)
                           }
                           accept="image/*"
            />
            <label htmlFor={unique}>{alt.text['ja']}</label>
        </div>
    }
}

export default FileUploadAlternative
