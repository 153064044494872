import * as React from 'react';
import { Component } from 'react';

class FilePreview extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            preview: props.preview
        };

        this.imageOnLoad = props.onLoad;
    }

    render() {
        let { preview } = this.state;
        return (<div className="preview">
            <img src={preview} alt="Preview"
                 onLoad={this.imageOnLoad} />
        </div>);
    }

}

export default FilePreview
