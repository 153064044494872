import React, {Component} from 'react';
import Bubble from './Bubble.js';
import IconBubble from './IconBubble';
import '../progress.css';
import classNames from 'classnames/bind';
import uuid from 'uuid';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';
import ja from 'moment/locale/ja';
import Div100vh from 'react-div-100vh'
import BankDetails from "./bankdetails/BankDetails";
import Validate from '../Validate'
import 'url-search-params-polyfill';
import notifier from 'simple-react-notifications2';
import "simple-react-notifications2/dist/index.css";
import TextInput from "./TextInput";

class Bubbles extends Component {
    constructor(props) {
        super(props);

        let defaultDate = this.setDefaultDate();

        this.textInput = React.createRef();
        this.currencyInput = React.createRef();
        this.emptyInput = {
            type: '',
            placeholder: '',
            value: ''
        };
        this.state = {
            defaultDate: defaultDate,
            itemIndex: 0,
            flags: false,
            photo: null,
            isUploading: false,
            nextBubbles: [],
            currencyValue: null,
            currencyInput: null,
            input: this.emptyInput,
            isDatePickerOpen: false,
            isBankDetailsPickerOpen: false,
            allowTextInput: false,
            allowCurrencyInput: false
        };

        this.focusInputField = this.focusInputField.bind(this);
        this.addUserBubble = this.addUserBubble.bind(this);
        this.addImageBubble = this.addImageBubble.bind(this);

        notifier.configure({
            autoClose: 0,
            position: "top-center",
            single: true
        });
    }

    setDefaultDate() {
        let defaultDate = new Date();
        defaultDate.setMinutes(0);
        defaultDate.setSeconds(0);
        console.log('defaultDate == ' + defaultDate);
        return defaultDate;
    }

    componentDidMount() {
        this.getNextBubbles()
    }

    focusInputField(inputSettings) {
        this.setState({
            input: inputSettings
        });

        if (inputSettings['data-type'] === 'currency') {
            if (this.currencyInput) {
                this.currencyInput.current.focus();
            }
        } else {
            if (this.textInput && this.textInput.current)
                this.textInput.current.focus();
        }
    }

    resetInput() {
        if (this.currencyInput.current)
            this.currencyInput.current.value = '';

        if (this.textInput.current)
            this.textInput.current.value = '';

        this.setState({
            input: this.emptyInput,
            currencyValue: null
        });
    }

    saveDateTime = (picker) => {
        let that = this;

        let selectedTime = moment(picker).toISOString(true);
        let { input, nextBubbles } = this.state;

        this.setState({
            isDatePickerOpen: false
        });

        let updatedInput = input;

        updatedInput['datetime'] = selectedTime;
        updatedInput['itemIndex'] = this.state.itemIndex;

        moment.locale('ja');
        updatedInput['friendly'] = moment(picker).format('llll');

        this.addUserBubble(updatedInput, input['friendly']);

        fetch(process.env.REACT_APP_API_SERVER + '/api/v2/save', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedInput)
        })
            .then(this.handleFetchErrors)
            .then(res => res.json())
            .then(function (response) {
                nextBubbles.forEach(function(element) { element.visited = true });

                response.bubbles.map((bubble) => bubble.key = uuid.v1());

                response.bubbles.map((bubble) => nextBubbles.push(bubble));

                that.setState({
                    nextBubbles: nextBubbles
                });

                that.scrollToBottom();
                that.showNextPrompt();
            })
            .catch(this.catchAllFetchErrors);
    };

    handleInputClick = (e) => {
        let { input } = this.state;

        let datatype = input['data-type'];
        let minInclusiveLength = input['minInclusiveLength'] || 0;
        let maxInclusiveLength = input['maxInclusiveLength'] || 0;

        if (datatype === 'text') {
            input['value'] = this.textInput.current.value;
            const userInputLength = input.value.length

            const exceedsMaxLength = maxInclusiveLength > 0 && userInputLength >= maxInclusiveLength
            const lessThanMinLength = minInclusiveLength > 0 && userInputLength < minInclusiveLength

            const maxInclusiveText = (maxInclusiveLength > 0) ? (maxInclusiveLength) + '文字以内' : ''
            const minInclusiveText = (minInclusiveLength > 0) ? (minInclusiveLength) + '文字以上' : ''

            let warning = null
            if (exceedsMaxLength && lessThanMinLength) {
                warning = minInclusiveText + maxInclusiveText
            } else if (exceedsMaxLength) {
                warning = maxInclusiveText
            } else if (lessThanMinLength) {
                warning = minInclusiveText
            } else {
                this.prepareSaveInput(input);
            }

            if (warning) {
                notifier.info(warning  + "で入力してください。")
            }

        } else if (datatype === 'currency' && this.currencyInput.current && this.currencyInput.current.value) {
            if (Validate.validateCurrency(this.currencyInput.current.value)) {
                input['value'] = this.currencyInput.current.value;
                this.prepareSaveInput(input);
            } else {
                notifier.info('8桁以内で入力してください。');
            }
        }
    };

    prepareSaveInput = (input) => {
        this.setState({
            input: input,
            allowTextInput: false,
            allowCurrencyInput: false
        });

        this.saveInput();
    };

    saveInput() {
        let { input, nextBubbles } = this.state;
        let that = this;

        input['promptId'] = input['id'];
        input['itemIndex'] = this.state.itemIndex;

        this.addUserBubble(input, input['value']);

        fetch(process.env.REACT_APP_API_SERVER + '/api/v2/save', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input)
        })
            .then(this.handleFetchErrors)
            .then(res => res.json())
            .then(function (response) {
                that.resetInput();

                nextBubbles.forEach(function(element) { element.visited = true });

                response.bubbles.map((bubble) => bubble.key = uuid.v1());

                response.bubbles.map((bubble) => nextBubbles.push(bubble));

                that.setState({
                    nextBubbles: nextBubbles
                });

                that.scrollToBottom();
                that.showNextPrompt();
            })
            .catch(this.catchAllFetchErrors);
    }

    saveAlternative(data) {
        let { nextBubbles } = this.state;
        const that = this;

        data['itemIndex'] = this.state.itemIndex;

        this.addUserBubble(data);

        fetch(process.env.REACT_APP_API_SERVER + '/api/v2/save', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(this.handleFetchErrors)
            .then(res => res.json())
            .then(function (response) {
                that.updateItemCounter(response.bubbles);

                nextBubbles.forEach(function(element) { element.visited = true });

                response.bubbles.map((bubble) => bubble.key = uuid.v1());

                response.bubbles.map((bubble) => nextBubbles.push(bubble));

                that.setState({
                    nextBubbles: nextBubbles
                });

                that.scrollToBottom();
                that.showNextPrompt();

                that.checkStpFlags();
            })
            .catch(this.catchAllFetchErrors);
    }

    checkStpFlags() {
    }


    /**
     * Convert a base64 string in a Blob according to the data and contentType.
     *
     * @param b64Data {String} Pure base64 string without contentType
     * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
     * @param sliceSize {Int} SliceSize to process the byteCharacters
     * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @return Blob
     */
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    saveUpload(clickInfo) {
        let that = this;

        let blob = this.state.photo.split(";");
        let contentType = blob[0].split(":")[1];
        let fileData = blob[1].split(",")[1];

        let formData = new FormData();
        formData.append('img', this.b64toBlob(fileData, contentType, 512));
        formData.append('promptId', clickInfo.promptId);
        formData.append('itemIndex', this.state.itemIndex);

        this.setState({
            isUploading: true
        });

        fetch(process.env.REACT_APP_API_SERVER + '/api/v2/upload', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: formData
        })
            .then(this.handleFetchErrors)
            .then(function (response) {
                // TODO specify previous bubble
                console.debug('input.promptId: ' + clickInfo.promptId);
                that.setState({
                    isUploading: false,
                    photo: null
                });
                that.getNextBubbles(clickInfo.promptId, clickInfo.alternativeId);
            })
            .catch(function (error) {
                const { nextBubbles } = that.state;
                let updatedNextBubbles = nextBubbles.slice();

                let lastBubble = nextBubbles[nextBubbles.length-1];
                updatedNextBubbles.push(lastBubble);
                that.setState({
                    nextBubbles: updatedNextBubbles
                });

                notifier.info('アップロードできませんもう一度実行してください');
            });

    }

    addUserBubble (res, label) {
        const { nextBubbles } = this.state;
        const userBubble =
            {
                id : 'response-' + res.promptId,
                key : 'response-' + res.promptId,
                text : {
                    ja : label ? label : res.label
                },
                visited: false,
                type: 'UserResponsePrompt'
            };

        nextBubbles.push(userBubble);

        this.setState({
            nextBubbles: nextBubbles
        });
    };

    addImageBubble(base64ImageData, originalId, alternativeId) {
        const { nextBubbles } = this.state;
        console.debug('originalId = ' + originalId);
        console.debug('alternativeId = ' + alternativeId);
        const imageBubble =
            {
                id : originalId,
                key : uuid.v1(),
                text : {
                    en : 'Is this photo acceptable?',
                    ja : 'この写真でよろしいですか？'
                },
                preview: base64ImageData,
                type : 'ImageConfirmationPrompt',
                visited: false,
                alternatives : [
                    {
                        key : alternativeId,
                        text : {
                            en : 'Yes',
                            ja : 'この写真を送る'
                        },
                        takePhoto : false,
                        icon : null
                    },
                    {
                        key : 'no',
                        text : {
                            en : 'No',
                            ja : '撮りなおす'
                        },
                        takePhoto : false,
                        icon : null
                    }
                ],
            };

        nextBubbles.forEach(function(element) { element.visited = true });

        nextBubbles.push(imageBubble);

        this.setState({
            nextBubbles: nextBubbles
        });

        // TODO Delay or check difference between states
        this.scrollToBottom();
        let those = this;
        setTimeout(function() {
            those.scrollToBottom();
        }, 500);
    }

    handleListBubbleImageSelected = (files, originalId, alternativeId) => {
        console.debug('Parent received image');
        console.debug('originalId == ' + originalId);
        console.debug('alternativeId == ' + alternativeId);
        const file = files[0];

        const allowedFileTypes = ["image/png", "image/jpeg"];

        if (file && window.FileReader && window.Blob) {
            if (allowedFileTypes.indexOf(file.type) > -1) {
                try {
                    const reader = new FileReader();
                    reader.onerror = (event) => {
                        reader.abort();
                        notifier.error("アップロードができない。");
                    };
                    reader.onloadend = () => {
                        const imgData = reader.result;
                        // TODO Warn and catch if image size exceeds localstorage
                        this.setState({
                            photo: imgData
                        });
                        this.addImageBubble(imgData, originalId, alternativeId);
                    };
                    reader.readAsDataURL(file);
                } catch (e) {
                    console.debug("Storage failed: " + e);
                }
            } else {
                notifier.info("JPEG、PNGのいずれかの形式のファイルのみでアップロードを試してください。");
            }
        } else {
            notifier.error("アップロードができない。");
        }
    };

    handleSave = (clickInfo) => {
        let that = this;
        if (clickInfo.type === 'ImageConfirmationPrompt') {
            if (clickInfo.alternativeId !== 'no') {
                this.saveUpload(clickInfo);
            } else {
                const { nextBubbles } = that.state;
                let updatedNextBubbles = nextBubbles.slice();
                let lastBubbles = nextBubbles.slice(-2, -1);
                if (lastBubbles.length === 1) {
                    let bubble = lastBubbles[0];
                    bubble.visited = false;
                    updatedNextBubbles.push(bubble);
                    that.setState({
                        nextBubbles: updatedNextBubbles
                    });
                    this.scrollToBottom();
                }
            }
        } else {
            this.saveAlternative(clickInfo);
        }
    };

    scrollToBottom = (e) => {
        let that = this;
        console.debug('==> scroll to bottom');
        that.messagesEnd.scrollIntoView({ behavior: "smooth" });
        setTimeout(function() {
            that.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }, 400);
    };

    getNextBubbles (promptId, alternativeId) {
        let { nextBubbles } = this.state;
        this.setState({
            isUploading: true
        });

        console.debug('getNextBubbles: ' + promptId + ',' + alternativeId);

        let params = new URLSearchParams();
        if (alternativeId)
            params.append("alternativeId", alternativeId);
        if (promptId)
            params.append("promptId", promptId);

        fetch(process.env.REACT_APP_API_SERVER + '/api/v2/next?' + params.toString(), {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: ''
        })
            .then(this.handleFetchErrors)
            .then(res => res.json())
            .then((result) => {
                this.updateItemCounter(result.bubbles);

                nextBubbles.forEach(function(element) { element.visited = true });

                result.bubbles.map((bubble) => bubble.key = uuid.v1());

                result.bubbles.map((bubble) => nextBubbles.push(bubble));

                this.setState({
                    nextBubbles: nextBubbles,
                    isUploading: false
                });

                if (alternativeId && promptId)
                    this.scrollToBottom();

                this.showNextPrompt();

                this.checkStpFlags();
            })
            .catch(this.catchAllFetchErrors);
    }

    updateItemCounter(nextBubbles) {
        let updatedItemIndex = this.state.itemIndex;
        let bubbleIds = (nextBubbles.map((bubble) => bubble.id));

        if (bubbleIds.indexOf("does_mitsumori_exist") > -1) {
            updatedItemIndex = this.state.itemIndex + 1;
            console.debug('Updating item index: ' + updatedItemIndex);
        }

        this.setState({
            itemIndex: updatedItemIndex
        })
    }

    showNextPrompt () {
        let { nextBubbles } = this.state;
        if (nextBubbles.length > 0) {
            let lastBubble = nextBubbles[nextBubbles.length-1];
            if (lastBubble.type === 'InputPrompt' ||
                lastBubble.type === 'CameraPrompt' ||
                lastBubble.type === 'CurrencyInputPrompt') {

                lastBubble['label'] = lastBubble['text']['ja'];

                let minInclusiveLength = lastBubble['minInclusiveLength'];
                let maxInclusiveLength = lastBubble['maxInclusiveLength'];

                switch (lastBubble.type) {
                    case 'CameraPrompt':
                        lastBubble['type'] = 'file';
                        break;
                    case 'CurrencyInputPrompt':
                        lastBubble.type = 'text';
                        lastBubble['data-type'] = 'currency';
                        lastBubble.inputmode = 'numeric';
                        lastBubble.pattern = '[0-9]*';
                        if (minInclusiveLength && minInclusiveLength > 0 && maxInclusiveLength) {
                            lastBubble.placeholder = minInclusiveLength + '桁以上' + maxInclusiveLength + '桁以内で入力してください。';
                        } else if (maxInclusiveLength && maxInclusiveLength > 0) {
                            lastBubble.placeholder = maxInclusiveLength + '桁以内で入力してください。';
                        }
                        this.setState({
                            allowCurrencyInput: true
                        });
                        this.focusInputField(lastBubble);
                        break;
                    default:
                        if (minInclusiveLength && minInclusiveLength > 0 && maxInclusiveLength) {
                            lastBubble.placeholder = minInclusiveLength + '文字以上' + maxInclusiveLength + '文字以内で入力してください。';
                        } else if (maxInclusiveLength && maxInclusiveLength > 0) {
                            lastBubble.placeholder = maxInclusiveLength + '文字以内で入力してください。';
                        }
                        lastBubble.type = 'text';
                        lastBubble['data-type'] = 'text';
                        lastBubble.inputmode = 'search';

                        this.setState({
                            allowTextInput: true
                        });
                        this.focusInputField(lastBubble);
                }

                this.scrollToBottom();
            }

            if (lastBubble.type === 'CalendarPrompt') {
                let defaultDate = this.setDefaultDate();
                this.setState({
                    isDatePickerOpen: true,
                    input: {
                        defaultDate: defaultDate,
                        type: 'CalendarPrompt',
                        promptId: lastBubble.id,
                        label: lastBubble.text['ja']
                    }
                });

                nextBubbles.pop();
            } else if (lastBubble.type === 'BankDetailsPrompt') {
                this.setState({
                    isBankDetailsPickerOpen: true,
                    input: {
                        type: 'BankDetailsPrompt',
                        promptId: lastBubble.id,
                        label: lastBubble.text['ja']
                    },
                    bankDetails: {
                        selectionMode: 'bank',
                        bankId: null,
                        bankName: null
                    }
                });
            }

        }
    }

    handleLaunchBankDetails = (e) => {
        let { nextBubbles } = this.state;
        let lastBubble = nextBubbles[nextBubbles.length-1];
        let bankDetails = e.target.value.split('::');

        this.setState({
            isBankDetailsPickerOpen: true,
            input: {
                type: 'BankDetailsPrompt',
                promptId: lastBubble.id,
                label: lastBubble.text['ja']
            },
            bankDetails: {
                selectionMode: 'branch',
                bankId: bankDetails[0],
                bankName: bankDetails[1]
            }
        });
    };

    saveBankDetails = (data) => {
        let { nextBubbles } = this.state;
        const that = this;

        let lastBubble = nextBubbles[nextBubbles.length-1];
        data['type'] = 'BankDetailsPrompt';
        data['itemIndex'] = 0;
        data['promptId'] = lastBubble.id;
        data['label'] = lastBubble.text['ja'];

        this.addUserBubble(data);

        fetch(process.env.REACT_APP_API_SERVER + '/api/v2/save', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(this.handleFetchErrors)
            .then(res => res.json())
            .then(function (response) {
                that.updateItemCounter(response.bubbles);

                nextBubbles.forEach(function(element) { element.visited = true });

                response.bubbles.map((bubble) => bubble.key = uuid.v1());

                response.bubbles.map((bubble) => nextBubbles.push(bubble));

                that.setState({
                    nextBubbles: nextBubbles,
                    isBankDetailsPickerOpen: false
                });

                that.scrollToBottom();
                that.showNextPrompt();

                that.checkStpFlags();
            })
            .catch(this.catchAllFetchErrors);
    };

    catchAllFetchErrors = (error) => {
        console.error(error);
        notifier.error('マイページにログインされていません。トップページからアクセスしてください。\n [Fetch error]\n' + error);
    };

    handleFetchErrors = (response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    };

    render() {
        const { allowCurrencyInput, allowTextInput, flags, input, nextBubbles, isUploading,
            isBankDetailsPickerOpen, isDatePickerOpen, defaultDate } = this.state;

        const dateConfig = {
            'year': {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },
            'month': {
                format: 'MM',
                caption: 'Mon',
                step: 1,
            },
            'date': {
                format: 'DD',
                caption: 'Day',
                step: 1,
            },
            'hour': {
                format: 'hh',
                caption: 'Hour',
                step: 1,
            },
            'minute': {
                format: 'mm',
                caption: 'Min',
                step: 15,
            }
        };

        let bubbles = (nextBubbles||[]).map((bub) => {
                if (bub.type === 'IconAlternativePrompt') {
                    return <IconBubble key={bub.key}
                                       data={bub}
                                       onSave={this.handleSave}
                    />
                } else {
                    return <Bubble key={bub.key}
                                   data={bub}
                                   onLaunchBankDetails={this.handleLaunchBankDetails}
                                   onListBubbleImageSelected={this.handleListBubbleImageSelected}
                                   onSave={this.handleSave}
                                   onLoad={this.scrollToBottom}
                    />
                }
            }
        );

        let inputClassName1,
            inputClassName2;

        if (input['data-type'] === 'currency') {
            inputClassName1 = 'hide';
            inputClassName2 = 'input-container visible';
        } else if (input['data-type'] === 'text') {
            inputClassName1 = 'input-container visible';
            inputClassName2 = 'hide';
        } else {
            inputClassName1 = 'hide';
            inputClassName2 = 'hide'
        }

        return <div className="all-bubbles-container">
            <div className={classNames({progress: isUploading})}>
                <div className="indeterminate"></div>
            </div>
            {bubbles}
            <DatePicker
                theme={'ios'}
                max={new Date()}
                value={defaultDate}
                isOpen={isDatePickerOpen}
                dateConfig={dateConfig}
                cancelText={'Cancel'}
                confirmText={'OK'}
                headerFormat={'YYYY年MM月DD日 hh:mm'}
                onSelect={this.saveDateTime}
                onCancel={() => notifier.info('日付を入力ください')}
            />
            <BankDetails
                isDisplayed={isBankDetailsPickerOpen}
                bankDetails={this.state.bankDetails}
                onSubmit={this.saveBankDetails}
            />
            <Div100vh className={inputClassName1}
                      style={{maxHeight: '150px'}}>
                <div className="input-container-flex">
                    <TextInput
                        input={input}
                        forwardedRef={this.textInput}
                    />
                    <button
                        onClick={this.handleInputClick}
                        disabled={!allowTextInput}
                        id='submit-text'
                    >送信する
                    </button>
                </div>
            </Div100vh>
            <Div100vh className={inputClassName2}
                      style={{maxHeight: '50px'}}>
                <div className="input-container-flex">
                    <input type="number"
                           ref={this.currencyInput}
                           inputMode="numeric"
                           pattern="[0-9]*"
                           placeholder="8桁以内で入力してください。"
                           max="99999999"
                           autoComplete="off"
                    />
                    <div className={'suffix'}>円</div>
                    <button
                        onClick={this.handleInputClick}
                        disabled={!allowCurrencyInput}
                        id='submit-currency'
                    >送信する
                    </button>
                </div>
            </Div100vh>
            <div id="bottom-element" ref={(el) => {
                this.messagesEnd = el;
            }}></div>
            <div className={classNames({flag: flags, noflag: !flags})}>
                <img src="/images/redflag.svg" alt="flag"/>
            </div>
        </div>
    }
}

export default Bubbles;
