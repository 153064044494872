import React, {Component} from 'react';
import './BankDetails.css';


class BankSelectionList extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            selectionMode: this.props.selectionMode
        };
        this.placeholder = this.props.placeholder;
        this.onSelect = this.props.onSelect;
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            selectionMode: nextProps.selectionMode
        });
    };

    handleSelection = (e) => {
        this.onSelect(e.target.value);
    };

    render()
    {
        let { selectionMode } = this.state;
        let matching = this.props.matching || [];

        let resultList = matching
            .map((b) =>
                <div className='selection' key={b.id}>
                <button
                    value={selectionMode === 'bank' ? b.bankId + ':' + b.name : b.branchId + ':' + b.name}
                    onClick={this.handleSelection}>{b.name}</button>
            </div>);

        let displayList = (selectionMode === 'account-type' && selectionMode !== 'account' && selectionMode !== 'confirm')
            || (this.props.query && this.props.query.length > 0);

        return (displayList) ? (<div>
                    <div className='selection-list'>
                        <div className={'selection-matching'}>{matching.length}件</div>
                        {resultList}
                    </div>
                </div>) : '';
    }
}

export default BankSelectionList;
