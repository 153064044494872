import React, { Component } from 'react';
import FilePreview from './FilePreview';
import FileUploadAlternative from './alternatives/FileUploadAlternative';
import ModalImage from '../react-modal-image'
import uuid from 'uuid';


class Bubble extends Component {
    constructor(props) {
        super(props);

        this.LANGUAGE = 'ja';

        this.state = {
            currentValue: props.currentValue,
            clicked: false,
            data: props.data || {}
        };

        if (!props.onListBubbleImageSelected)
            console.log("WARNING: onListBubbleImageSelected attribute missing on Bubble");

        if (!props.onSave)
            console.log("WARNING: onSave attribute missing on Bubble");

        this.propagateSave = props.onSave;
        this.imageOnLoad = props.onLoad;
        this.handleAlternativeClick = this.handleAlternativeClick.bind(this);
        this.handleFileChange = props.onListBubbleImageSelected;
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    isUserResponse() {
        const { type } = this.state.data;
        return type === 'UserResponsePrompt';
    }

    handleAlternativeJsCallback = (e) => {
        this.props.onLaunchBankDetails(e);
    };

    handleAlternativeClick (e) {
        let arr = e.target.value.split('::');
        let clickInfo = {
            promptId: arr[0],
            alternativeId: arr[1],
            label: arr[2],
            type: arr[3]
        };

        this.setState({
            clicked: true
        });

        this.propagateSave(clickInfo);
    }

    render () {
        const {alternatives, alternativeExample, buttons, clicked, confirmationText, example, preview, text, visited} = this.state.data;
        const {data} = this.state;

        let className = 'bubble-container';

        if (visited || clicked) {
            className += ' visited'
        }

        if (this.isUserResponse()) {
            className += ' user'
        } else {
            className += ' zurich'
        }

        let confirmation = null;
        if (confirmationText && confirmationText.length > 0) {
            confirmation = confirmationText.map((t) => <div key={uuid.v1()}>{t}</div>);
        }

        let notes = (data.notes && data.notes[this.LANGUAGE]) ? data.notes[this.LANGUAGE].map((t) => <p
            key={t}>{t}</p>) : '';

        let examplePhoto = (example && example.image) ? '/images/' + example.image + '.jpg?' : null;
        let exampleCaption = (example && example.caption) ? example.caption : '';
        let altEx = (alternativeExample && alternativeExample[this.LANGUAGE]) ? alternativeExample[this.LANGUAGE] : null;
        let examplePhotoContainer = (examplePhoto) ? (
            <div className="example">
                <ModalImage
                    className="example-image"
                    caption={exampleCaption}
                    alternativeCaption={altEx}
                    small={examplePhoto}
                    large={examplePhoto}
                    onLoad={this.imageOnLoad}
                />
            </div>
        ) : ("");

        let bubbleAlternatives = (alternatives || []).map((alt) => {
            if (alt.jsCallback && Object.keys(alt.jsCallback).length > 0) {
                return <div className="option center" key={alt.key}>
                    <button disabled={this.state.clicked}
                            value={Object.keys(alt.jsCallback)[0] + '::' +
                            Object.values(alt.jsCallback)[0]}
                            onClick={this.handleAlternativeJsCallback}
                    >{alt.text[this.LANGUAGE]}</button>
                </div>
            } else if (alt.takePhoto) {
                return <div className="option center" key={alt.key}>
                    <FileUploadAlternative promptId={data.id}
                                           disabled={clicked === true || visited === true}
                                           unique={'unique' + alt.key}
                                           alt={alt}
                                           onFileChange={this.handleFileChange}
                    />
                </div>
            } else {
                return <div className="option center" key={alt.key}>
                    <button disabled={this.state.clicked}
                            value={data.id + '::' + alt.key + '::' + alt.text[this.LANGUAGE]
                            + '::' + data.type}
                            onClick={this.handleAlternativeClick}
                    >{alt.text[this.LANGUAGE]}</button>
                </div>
            }
        });

        let bubbleButtonsKV = [];
        if (buttons && buttons['ja']) {
            for (const key in buttons[this.LANGUAGE]) {
                if (buttons[this.LANGUAGE].hasOwnProperty(key)) {
                    let value = buttons[this.LANGUAGE][key];
                    bubbleButtonsKV.push({key: key, value: value})
                }
            }
        }
        let bubbleButtons = bubbleButtonsKV.map((btn) => {
            return <div className='bubble-button'><a href={btn.value}>{btn.key}</a></div>
        });

        return (text && (text[this.LANGUAGE] || text['en']) && (text[this.LANGUAGE].length > 0 || text['en'].length > 0)) ?
            (<div className="bubble-fullwidth">
                <div className={className}>
                    {!this.isUserResponse() ? (
                        <div className="avatar"/>
                    ) : ("")}
                    <div className="bubble">
                        <div className="main">
                            {text[this.LANGUAGE]}
                            {notes}
                            {(example && example.text && example.text[this.LANGUAGE]) ? (
                                <div className="example">
                                    {example.text[this.LANGUAGE]}
                                </div>
                            ): ("")}
                        </div>
                        {(confirmation) ? (
                        <div className="confirmation">
                            {confirmation}
                        </div>) : ("")}
                        {examplePhotoContainer}
                        {(preview) ? (
                            <FilePreview preview={preview}
                                         onLoad={this.imageOnLoad} />
                        ): ("")}
                        {bubbleAlternatives}
                    </div>
                </div>
                {bubbleButtons}
            </div>) : (<div></div>)
    }
}

export default Bubble;
