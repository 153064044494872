import React, {Component} from 'react';
import './BankDetails.css';
import BankSelectionList from './BankSelectionList';
import Validate from '../../Validate';
import notifier from "simple-react-notifications2";
import "simple-react-notifications2/dist/index.css";

class BankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matching: [],
            accountType: null,
            accountNumber: null,
            bankId: null,
            bankName: null,
            branchId: null,
            branchName: null,
            selectionMode: 'bank'
        };
        this.queryInput = React.createRef();
        notifier.configure({
            autoClose: 0,
            position: "bottom-center"
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.bankDetails) {
            this.setState({
                bankId: nextProps.bankDetails.bankId,
                bankName: nextProps.bankDetails.bankName,
                selectionMode: nextProps.bankDetails.selectionMode
            });
        }
    };

    reset() {
        this.setState({
            matching: [],
            accountType: null,
            accountNumber: null,
            bankId: null,
            bankName: null,
            branchId: null,
            branchName: null,
            selectionMode: 'bank'
        });
        this.resetInput();
    }

    resetInput() {
        this.setState({
            matching: []
        });

        if (this.queryInput && this.queryInput.current)
            this.queryInput.current.value = '';
    }

    handleValueSelection = (id) => {
        let { bankId, branchId, accountNumber, accountType } = this.state;
        let details = id.split(':');
        let updated = {};
        if (bankId === null && branchId === null && accountNumber === null && accountType === null) {
            updated = {
                bankId: details[0],
                bankName: details[1],
                selectionMode: 'branch'
            }
        } else if (branchId === null && accountNumber === null && accountType === null) {
            updated = {
                branchId: details[0],
                branchName: details[1],
                selectionMode: 'account-type',
                isDisplayed: false
            }
        } else if (accountType === null && accountNumber === null) {
            updated = {
                accountType: details[1],
                selectionMode: 'account',
                isDisplayed: false
            }
        } else if (accountNumber === null) {
            updated = {
                accountNumber: id,
                selectionMode: 'confirm',
                isDisplayed: false
            }
        }
        this.setState(updated);
        this.resetInput();
    };

    handleInputChange = (e) => {
        let { bankId, selectionMode } = this.state;
        let that = this;

        if (selectionMode !== 'account') {
            let query = {
                query: this.queryInput.current.value,
                selectionMode: selectionMode,
                bankId: bankId
            };

            fetch(process.env.REACT_APP_API_SERVER + '/bankquery', {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(query)
            })
                .then(res => res.json())
                .then(function (response) {
                    let matching = response.result;

                    console.log('matching: ' + matching.length);

                    that.setState({
                        matching: matching
                    });
                })
                .catch(function (error) {
                    console.debug(error);
                });
            }
    };

    padWithZeroes(value, number) {
        if (value && value.length < 7 && String.prototype.repeat) {
            const zeroCount = 7 - value.length
            return '0'.repeat(zeroCount) + value
        } else {
            return value
        }
    }

    handleAccountNumber = (e) => {
        if (this.queryInput.current && this.queryInput.current.value && this.queryInput.current.value.length > 7) {
            notifier.info('7桁以内で入力してください。');
        } else if (this.queryInput.current && this.queryInput.current.value && Validate.validateBankAccount(this.queryInput.current.value)) {
            this.handleValueSelection(this.padWithZeroes(this.queryInput.current.value, 7));
        } else {
            notifier.info('口座番号（半角）を入力してください。');
        }
    };

    handleSubmit = (e) => {
        console.log('handleSubmit');
        let { accountType, accountNumber, bankId, bankName, branchId, branchName } = this.state;
        if (bankId !== null && branchId !== null && accountNumber !== null) {
            this.props.onSubmit({
                bankId: bankId,
                bankName: bankName,
                branchId: branchId,
                branchName: branchName,
                accountNumber: accountNumber,
                accountType: accountType
            });

            this.reset();
        }
    };

    render() {
        let { accountType, accountNumber, bankId, bankName, branchId, branchName, matching, selectionMode } = this.state;

        let isDisplayed = this.props.isDisplayed;

        let currentQuery = (this.queryInput.current) ? this.queryInput.current.value : '';

        let helpText = '';
        switch (selectionMode) {
            case 'bank':
                helpText = '金融機関名を入力してください。';
                break;
            case 'branch':
                helpText = '支店名または支店番号を入力してください。';
                break;
            case 'account-type':
                helpText = '口座種類を選択してください。';
                matching = [
                    { id: "100", branchId: "100", name: "普通・総合" },
                    { id: "200", branchId: "200", name: "当座" }
                ];
                break;
            case 'account':
                helpText = '口座番号（半角）を入力してください。';
                break;
            case 'confirm':
                break;
            default:
        }

        let buttonClass = (selectionMode === 'account') ? '' : 'hidden-button';
        let inputMode = (selectionMode === 'account') ? 'numeric' : 'search';

        let inputContainer = (selectionMode !== 'confirm') ? <div className='input-container'>
            <BankSelectionList
                bankId={bankId}
                placeholder={helpText}
                selectionMode={selectionMode}
                matching={matching}
                onSelect={this.handleValueSelection}
                query={currentQuery}
            />
            <div className='input-container-flex'>
                <input type='text'
                       placeholder={helpText}
                       autoComplete='off'
                       inputMode={inputMode}
                       onChange={this.handleInputChange}
                       ref={this.queryInput}
                />
                <button
                    className={buttonClass}
                    onClick={this.handleAccountNumber}
                    id='submit-currency'
                >送信する</button>
            </div>
        </div> : '';

        let confirmationButton = (selectionMode === 'confirm') ? <button onClick={this.handleSubmit} className='confirmation-button'>了解</button> : '';

        let accountTypeString = accountType ? '(' + accountType + ')' : '';
        let branchIdString = branchId ? '(' + branchId + ')' : '';

        return  (isDisplayed) ?
            (<div className='lightbox-background'>
                <div className='bank-details'>
                    <div>{bankName}</div>
                    <div>{branchName} {branchIdString}</div>
                    <div>{accountTypeString} {accountNumber}</div>
                    <div className='lightbox-help-text'>{helpText}</div>
                    {confirmationButton}
                </div>
                {inputContainer}
            </div>) : '';

    }
}

export default BankDetails;
