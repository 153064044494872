import React, { Component } from "react";

import Lightbox from "./Lightbox";

export { default as Lightbox } from "./Lightbox";

export default class extends Component {
  state = {modalOpen: false };

  toggleModal = () => {
    this.setState(prev => ({
      modalOpen: !prev.modalOpen
    }));
  };

  handleLoaded = () => {
    if (this.props.onLoaded)
      this.props.onLoaded();
  };

  render() {
    const {
      alternativeCaption,
      caption,
      className,
      small,
      smallSrcSet,
      medium,
      large,
      alt,
      hideDownload,
      hideZoom
    } = this.props;
    const { modalOpen } = this.state;

    let exampleCaption = (caption && !modalOpen) ? <div className="example-caption">{caption}</div> : '';
    let alternativeExampleCaption = (alternativeCaption && Object.keys(alternativeCaption).length > 0) ? alternativeCaption.map((txt) => {
          return <p>{txt}</p>
        }
    ) : null;
    let wrappedAlternativeCaption = (alternativeExampleCaption) ? <div className="example-caption tax-example-caption">{alternativeExampleCaption}</div> : '';

    return (
      <div>
        {wrappedAlternativeCaption}
        {exampleCaption}
        <img
          className={className}
          style={{
            cursor: "pointer",
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          onClick={this.toggleModal}
          src={small}
          srcSet={smallSrcSet}
          alt={alt}
        />
        {modalOpen && (
          <Lightbox
              caption={caption}
              medium={medium}
              large={large}
              alt={alt}
              onClose={this.toggleModal}
              onLoaded={this.handleLoaded}
              hideDownload={hideDownload}
              hideZoom={hideZoom}
          />
        )}
      </div>
    );
  }
}
