class Validate {
    static validateCurrency = (valueToTest) => {
        let currencyRegex = /^[0-9０−９]*$/;
        return currencyRegex.test(valueToTest)
    };

    static validateBankAccount = (valueToTest) => {
        let bankAccountRegex = /^[0-9０−９]*$/;
        return bankAccountRegex.test(valueToTest);
    };

    static isNumeric = (numberCandidate) => {
        let numericRegex = /^[0-9０−９]+$/;
        return numericRegex.test(numberCandidate);
    };
}

export default Validate;