import React, { Component } from 'react';

class TextInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            input: props.input,
            forwardedRef: props.forwardedRef,
            data: props.data || {
            }
        };
    }

    render() {
        const { forwardedRef } = this.state;
        const { type, placeholder, value, pattern, inputMode, multiline } = this.props.input;

        if (!multiline) {
            return (<input
                type={type}
                placeholder={placeholder}
                defaultValue={value}
                inputMode={inputMode}
                pattern={pattern}
                autoFocus={true}
                autoComplete="off"
                ref={forwardedRef}
            />)
        } else {
            return (<textarea
                placeholder={placeholder}
                defaultValue={value}
                inputMode={inputMode}
                autoFocus={true}
                autoComplete="off"
                ref={forwardedRef}
            />)
        }
    }
}

export default TextInput;
