import React, { Component } from 'react';
import Bubbles from './bubbles/Bubbles';
import './App.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version: null
        }
    }

    render() {
        let { version } = this.state;
        let versionDiv = (version) ? <div>{version}</div> : '';

        return (
            <div className="App">
                <div className="navbar">
                    チューリッヒ少額短期保険
                </div>

                {versionDiv}

                <Bubbles />
            </div>
        );
    }

}

export default App;
