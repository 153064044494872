import React, { Component } from 'react';

class IconBubble extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentValue: props.currentValue,
            clicked: false,
            data: props.data || {
                type: null,
                id: null,
                text: {},
                alternatives: []
            }
        };

        this.propagateSave = props.onSave;
        this.handleAlternativeClick = this.handleAlternativeClick.bind(this);
    }

    isUserResponse() {
        const { type } = this.state.data;
        return type === 'UserResponsePrompt';
    }

    handleAlternativeClick (e) {
        let { type } = this.state.data;

        let arr = e.target.value.split('::');
        let clickInfo = {
            promptId: arr[0],
            alternativeId: arr[1],
            label: arr[2],
            type: type
        };

        this.setState({
            clicked: true
        });

        this.propagateSave(clickInfo);
    }

    render() {
        const { clicked, data } = this.state;
        const { id, text } = (data) ? data : { id: 1, text: ''};
        const bubbleAlternatives = (data && data.alternatives) ? data.alternatives : [];

        let className = 'bubble-container';

        if (clicked) {
            className += ' visited'
        }

        if (this.isUserResponse()) {
            className += ' user'
        } else {
            className += ' zurich'
        }

        return <div className="bubble-fullwidth">
            <div className={className}>
                <div className="avatar"/>
                <div className="bubble">
                    <div className="icon-bubble-main">
                        {text['ja']}
                    </div>
                    <div className='icon-bubble'>
                        <ul className="icon-grid">
                            {bubbleAlternatives.map((alt) =>
                            <li className="icon-option" key={alt.key}>
                                <button disabled={this.state.clicked}
                                        value={id + '::' + alt.key + '::' + alt.text['ja']}
                                        onClick={this.handleAlternativeClick}
                                        className={'icon-option-button ' + alt.icon}
                                        data-testid={id + '-' + alt.key + '-icon'}
                                />
                                <button disabled={this.state.clicked}
                                        value={id + '::' + alt.key + '::' + alt.text['ja']}
                                        onClick={this.handleAlternativeClick}
                                        className={'icon-option-text-button'}
                                        data-testid={id + '-' + alt.key + '-text'}
                                >{alt.text['ja']}</button>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default IconBubble;
